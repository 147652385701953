import { Component, NgZone, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "lmt-ui";

  private resizeObserver: ResizeObserver;
  private mutationObserver!: MutationObserver;
  private currentHeight: number = 0;

  constructor(private router: Router, private translate: TranslateService, private zone: NgZone) {}

  ngOnInit() {
    this.evaluateLanguage();
    this.observeRouteChange();
    this.observeBodyHeight();
    this.observeLanguageChange();
  }

  private evaluateLanguage() {
    const lang = this.loadLanguage();
    this.translate.use(lang);
  }

  private loadLanguage() {
    // Retrieve "lang" from the URL query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const queryLang = urlParams.get("lang");
    if (queryLang) return queryLang;

    // Retrieve "lang" from localStorage
    const localStorageLang = localStorage.getItem("current-language");
    if (localStorageLang) return localStorageLang;

    return "en";
  }

  private insideIFrame() {
    try {
      return window.self !== window.top;
    } catch (e) {
        return true;
    }
  }

  private observeRouteChange() {
    this.router.events
      .pipe(filter((ev) => ev instanceof NavigationEnd))
      .subscribe((ev: NavigationEnd) => {
        if (!this.insideIFrame()) {
          const slugs = {
            de: "produktwelt",
            en: "productworld",
          };
          const modifiedUrl = `https://lmtgmbh.de/${
            slugs[this.translate.currentLang] || slugs["en"]
          }/${this.updateQueryString(location.search, {
            path:
              ev.urlAfterRedirects && ev.urlAfterRedirects.length > 1
                ? ev.urlAfterRedirects.substring(1)
                : "",
          })}`;
          if (location.hostname != "localhost") location.href = modifiedUrl;
        }
      });
  }

  private observeBodyHeight(): void {
    this.zone.runOutsideAngular(() => {
      const body = document.body;

      this.resizeObserver = new ResizeObserver(() => {
        this.sendHeightToParent();
      });
      this.resizeObserver.observe(body);
      this.mutationObserver = new MutationObserver(() => {
        this.sendHeightToParent();
      });
      this.mutationObserver.observe(body, {
        attributes: true,
        childList: true,
        characterData: true
      });

      this.sendHeightToParent();
    });
  }

  private observeLanguageChange() {
    this.translate.onLangChange.subscribe((ev: any) => {
      localStorage.setItem("current-language", ev.lang);
    });
  }

  private updateQueryString(
    query: string,
    params: Record<string, string>
  ): string {
    const queryParams = new URLSearchParams(query);

    for (const [key, value] of Object.entries(params)) {
      queryParams.set(key, String(value));
    }

    return `?${queryParams.toString()}`;
  }

  private sendHeightToParent(): void {
    if (!window.parent) return;

    const height = document.body.offsetHeight;
    if (height != this.currentHeight) {
      this.currentHeight = height;
      window.parent.postMessage({ type: "height", height }, "*");
    }
  }
}
